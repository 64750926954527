import React from "react";
import Button from "./Button";
import HeroImage from "../assets/hero1.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { Link } from "react-router-dom";
//import ClientSlider from "./ClientSlider";

const Hero = () => {
  return (
    <div className="h-auto md:h-[680px] w-full bg-[var(--primary)] overflow-hidden relative">
      <div className="max-w-[1280px] mx-auto md:flex md:justify-between md:items-center px-4 h-full">
        <motion.div
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="pt-20 pb-8 md:pt-0 md:pb-0"
        >
          <h1 className="capitalize pb-5 text-3xl font-bold md:leading-tight tracking-tight md:text-4xl lg:text-[40px] text-white max-w-2xl">
            unlock your business potential with innovative software solutions
          </h1>
          <p className="max-w-xl font-light text-lg text-white mt-0 pb-6">
            Lumpsum Technologies Ltd provides cutting-edge software products
            tailored to meet the unique needs of businesses. Our solutions
            empower companies to streamline operations, boost productivity, and
            drive growth.
          </p>
          <Button>
            <Link to="/about-us">Learn More</Link>
          </Button>
        </motion.div>

        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="pb-6 px-auto hidden md:block overflow-hidden"
        >
          <img className="h-[470px] w-[470px] " src={HeroImage} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
