import React from "react";
import { Link, NavLink } from "react-router-dom";
import { IoLogoLinkedin } from "react-icons/io5";
import { MdFacebook } from "react-icons/md";
import { BsTwitterX } from "react-icons/bs";
import { FaInstagram } from "react-icons/fa6";
import Logo from "../assets/lumpsum logo dark-bg.png";
import ScrollToTop from "react-scroll-to-top";
//import footerImage from "../assets/image002.png";

const Footer = () => {
  return (
    <div className="h-auto pt-6 pb-6 bg-[var(--primary)]">
      <div className="max-w-[1280px] mx-auto px-4 h-auto">
        <ScrollToTop
          smooth
          top={20}
          color="rgb(20,9,81)"
          className="flex justify-center place-items-center"
        />
        <div className="text-white md:py-8 grid grid-cols-2 md:grid-cols-3 gap-8">
          <div className="flex flex-col gap-10">
            <div>
              <NavLink to="/" className='cursor-pointer'>
                <img className="h-6" src={Logo} alt="" />
              </NavLink>
              <h2 className="font-light leading-8 tracking-wider py-4 text-lg">
                Experience the Power of Our Advanced
                <br /> Software Solutions
              </h2>
            </div>
            <div className="flex items-center gap-3 md:gap-6">
              <Link to="/">
                <IoLogoLinkedin className="cursor-pointer hover:text-[var(--secondary)]" size={25} />
              </Link>
              <Link to="/">
                <BsTwitterX className="cursor-pointer hover:text-[var(--secondary)]" size={25} />
              </Link>
              <Link to="/">
                <MdFacebook className="cursor-pointer hover:text-[var(--secondary)]" size={30} />
              </Link>
              <Link to="/">
                <FaInstagram className="cursor-pointer hover:text-[var(--secondary)]" size={25} />
              </Link>
            </div>
          </div>

          <div className="">
            <h1 className="uppercase font-light text-lg pb-8">
              usefull links
            </h1>
            <ul className="capitalize grid gap-6 font-extralight">
              <li className=" cursor-pointer text-base">
                <NavLink to="/" className="hover:text-[var(--secondary)]">
                  home
                </NavLink>
              </li>
              <li className=" cursor-pointer text-base">
                <NavLink
                  to="/about-us"
                  className="hover:text-[var(--secondary)]"
                >
                  about
                </NavLink>
              </li>
              <li className=" cursor-pointer text-base">
                <NavLink
                  to="/products"
                  className="hover:text-[var(--secondary)]"
                >
                  products
                </NavLink>
              </li>
              {/*<li className="cursor-pointer text-base md:text-xl">
                <NavLink
                  to="/services"
                  className="hover:text-[var(--secondary)]"
                >
                  services
                </NavLink>
              </li>*/}
              {/*<li className="py-1 md:py-1.5 cursor-pointer text-base md:text-xl">
                <NavLink to="/products">products</NavLink>
  </li>*/}
              <li className=" cursor-pointer text-base">
                <NavLink
                  to="/contact-us"
                  className="hover:text-[var(--secondary)]"
                >
                  contact
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="">
            <h1 className="uppercase font-light text-lg pb-8">
              contact us
            </h1>
            <div className="font-extralight grid gap-6">
              <p className="capitalize text-base py-1">
                lumpsum technologies
              </p>
              <p className="capitalize text-base py-1">
                p.o.box 123431,kampala
              </p>
              <p className="capitalize text-base py-1">
                plot 52, kampala Road
              </p>
            </div>
            <div className="pt-6">
              <div className="flex items-center font-extralight">
                <h3 className="uppercase text-base">Phone :</h3>
                <p className="text-base px-2">
                  +256 772247532 / +256 706899479
                </p>
              </div>
            </div>
            <div className="flex font-extralight pt-6 items-center">
              <h3 className="uppercase text-base">Email :</h3>
              <p className="text-base px-2">info@lumpsumtech.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
