import React, { useState } from "react";
import fav from "../assets/whitefav.png";
import { Link, NavLink } from "react-router-dom";
import Button from "./Button";
import { FaBars, FaTimes } from "react-icons/fa";
//import { IoChevronDownOutline } from "react-icons/io5";

//import { Dropdown } from "flowbite-react";

const Navbar = () => {
  //const [dropdownOpen, setDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);

 //const toggleDropdown = () => {
   // setDropdownOpen(!dropdownOpen);
  //};

  return (
    <nav className="bg-[var(--primary)] w-full z-20 py-3 shadow-sm fixed top-0">
      <div className="max-w-[1280px] mx-auto flex justify-between items-center">
        <Link
          to="/"
          className="gap-2  text-white cursor-pointer flex items-center px-4"
        >
          <div className="flex items-center">
            <span className="mr-2">
              <img className="w-5 h-auto" src={fav} alt="" />
            </span>

            <p className="font-semibold text-lg md:text-2xl">Lumpsum</p>
            <p className="font-extralight text-lg md:text-xl pt-1">
              Technologies
            </p>
          </div>
        </Link>

        <div
          onClick={() => setOpen(!open)}
          className="text-xl text-white px-4 right-4 top-6 cursor-pointer md:hidden"
        >
          {!open ? <FaBars /> : <FaTimes />}
        </div>

        <ul
          className={`font-light md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-[var(--primary)] md:z-auto z-[-1] left-0 w-full md:w-auto  gap-6 md:gap-8 pl-9 transition-all duration-500 ease-in ${
            open
              ? "top-20 pt-10 grid place-items-center px-0 gap-y-10 justify-evenly"
              : "top-[-490px]"
          }`}
        >
          <li className="text-white text-lg cursor-pointer">
            <NavLink to="/" className={"hover:text-[var(--secondary)]"}>
              Home
            </NavLink>
          </li>

          <li className="text-white text-lg cursor-pointer">
            <NavLink to="/about-us" className={"hover:text-[var(--secondary)]"}>
              About
            </NavLink>
          </li>
          <li className="text-white text-lg cursor-pointer">
            <NavLink to="/products" className={"hover:text-[var(--secondary)]"}>
              Products
            </NavLink>
          </li>

          

          <li className="text-white text-lg cursor-pointer">
            <NavLink
              to="/contact-us"
              className={"hover:text-[var(--secondary)]"}
            >
              Contact
            </NavLink>
          </li>
          <Button>
            <Link to="/contact-us">Get Started</Link>
          </Button>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
