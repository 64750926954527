import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kbs from "../assets/kbs.jpg";
import sunflower from "../assets/sunflower.jpg";
import topline from "../assets/Topline.jpg";
import western from "../assets/UFFORD.jpg";
import fintech from "../assets/sandc.jpg";
import ramuzat from "../assets/Ramuzat.jpg";
import power from "../assets/Power Microfinance.jpg";
import pambana from "../assets/Pambana.jpg";
import nsambya from "../assets/nsambya.jpg";

const ClientSlider = () => {
  return (
    <>
      <h1 className="pt-5 md:pt-10 capitalize mb-4 text-center text-xl font-bold leading-none tracking-tight md:text-2xl">
        We're Proud to Partner with
      </h1>
      <div className="flex bg-white items-center h-auto w-full space-x-16 overflow-hidden pt-5 group">
        <div className="flex animate-loop-scroll space-x-16 gap-4 group-hover:paused">
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={kbs}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={topline}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={fintech}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={sunflower}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={nsambya}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={power}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={western}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={ramuzat}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={pambana}
            alt=""
          />
        </div>
        <div
          className="flex space-x-16 animate-loop-scroll gap-4 group-hover:paused"
          aria-hidden="true"
        >
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={kbs}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={topline}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={fintech}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={sunflower}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={nsambya}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={power}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={western}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={ramuzat}
            alt=""
          />
          <img
            loading="lazy"
            className="max-w-none h-[50px]"
            src={pambana}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default ClientSlider;
