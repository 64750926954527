import React from "react";
import { Link } from "react-router-dom";

const products = [
  {
    id: 1,
    name: "Bankroll",
    description:
      "Welcome to Bankroll, the premier Loans Management System for lending companies. Our system is designed to streamline the loan process, making it more efficient and cost-effective for your business. With Bankroll, you can easily manage your loan portfolio and underwriting process. Our user-friendly interface allows you to automate the loan application process, upload necessary documents, and track the status of loan applications, all in one place.",
    image:
      "https://cdn.pixabay.com/photo/2018/07/15/18/05/bank-3540186_640.jpg",
    link: "/bankroll",
  },
  {
    id: 2,
    name: "Relync",

    description:
      "This system was designed to address real estate troubles faced by many property owners and managers ranging from basic property management to more comprehensive solutions that can handle a variety of real estate-related tasks that include Tenant and lease management, Property maintenance and repair tracking, Financial and accounting tools (e.g., rent collection, budgeting), Marketing and leasing tools (e.g., listings, lead generation)",
    image:
      "https://media.istockphoto.com/id/925320044/photo/woman-uses-digital-table-to-search-for-new-home.jpg?s=612x612&w=0&k=20&c=ZQKomFhCiNxj2jTwUYGo83joxpnw_7_fM_hD3xPUO-0=",
    link: "/relync",
  },
  {
    id: 3,
    name: "Cashnet",
    description:
      "Introducing cashnet - the ultimate accounting solution for small businesses. With Numbers, you can easily track your incomes and expenses without the need for an accountant. Our affordable annual pricing plan makes it accessible for any small business to take control of their finances. The system generates simple accounting reports, issues invoices and much more, making it the perfect tool for managing your financials.",
    image:
      "https://cdn.pixabay.com/photo/2018/10/11/10/19/invoice-3739354_640.jpg",
    link: "/cashnet",
  },
];

const Products = () => {
  return (
    <div className="max-w-[1280px] mx-auto px-4 h-auto mt-12 flex flex-col justify-center pb-8 pt-8">
      <h1 className="text-3xl font-bold text-center mt-10 mb-8">
        Our Products
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => (
          <div key={product.id} className="border rounded-lg p-4 shadow-lg">
            <img
              className="w-full h-52 object-cover rounded-t-lg"
              src={product.image}
              alt={product.name}
            />
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2">{product.name}</h2>
              <p className="font-light text-sm leading-relaxed mb-4 text-gray-700">
                {product.description}
              </p>

              <Link
                to={product.link}
                className="text-[var(--secondary)] text-sm cursor-pointer font-light hover:underline"
              >
                More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
