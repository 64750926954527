import React from "react";
import Merits from "../assets/smiling-ceo-his-office.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const Benefits = () => {
  return (
    <div className="h-auto md:h-[800px] w-full">
      <div className="max-w-[1280px] mx-auto flex flex-col-reverse md:flex-row-reverse items-center justify-between h-auto p-4 mt-10 md:mt-0 md:h-full gap-6">
        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.8 }}
          className="flex flex-col justify-center pt-8"
        >
          <div>
            <h1 className="text-2xl font-bold">
              Discover the Benefits of Using
              <br /> Lumpsum's Software Products
            </h1>
            <p className="text-lg font-light text-gray-700 dark:text-gray-400 pt-4">
              Increase efficiency, save costs, and scale your business with
              Lumpsum's software products.
            </p>
          </div>

          <div className="py-8 grid grid-cols-2 justify-center">
            <div>
              <h2 className="font-semibold text-xl">Increased Efficiency</h2>
              <p className="pt-3 md:pt-4 text-base text-gray-700 dark:text-gray-400 font-light">
                Streamline your operations and
                <br /> optimize productivity with our
                <br /> advanced software solutions.
              </p>
            </div>
            <div>
              <h2 className="font-semibold text-xl">Cost Savings</h2>
              <p className="pt-3 md:pt-4 text-base text-gray-700 dark:text-gray-400 font-light">
                Reduce expenses and maximize your
                <br /> ROI by leveraging our cost-effective
                <br /> software products.
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.5 }}
          className="pt-6 md:w-[800px] rounded-3xl bg-white shadow-xl"
        >
          <img className="rounded-3xl" src={Merits} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default Benefits;
