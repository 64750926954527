import React from "react";
import contactImage from "../assets/contactus.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";
import Button from "../components/Button";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const ContactUs = () => {
  return (
    <div className="h-auto relative">
      <img
        className="h-[400px] w-full object-cover"
        src={contactImage}
        alt=""
      />

      <div className="max-w-[1280px] mx-auto px-4">
        <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="max-w-[1280px] top-20 md:top-40 flex flex-col text-center items-center justify-center px-4 absolute"
        >
          <h1 className="capitalize font-medium text-2xl text-white">
            contact
          </h1>
          <p className="font-light text-white text-lg py-8">
            Thank you for your interest in Lumpsum Technologies Ltd! Whether you
            have a question about our products, need assistance or just want to
            say hello, we're here to help. You can reach us using the
            information below:
          </p>
        </motion.div>

        <div className="my-20 grid gap-6 px-4 lg:grid-cols-2 items-center">
          <div className="pb-3">
            <h2 className="font-medium text-2xl text-[var(--primary)]">
              Get in touch
            </h2>
            <p className="pt-3 font-light  md:pt-6 text-lg leading-relaxed">
              Please feel free to get in touch with us via any convenient means
              (phone, whatsapp, email, submit a contact form). We will be glad
              to answer your questions as soon as possible.
            </p>
            <div className="py-6 md:py-12">
              <div className="flex items-center font-light pt-3 md:pt-6 text-gray-700">
                <FaPhoneAlt size={20} className="text-[var(--primary)]" />
                <p className="text-base px-2">
                  +256 772247532 / +256 706899479
                </p>
              </div>
              <div className="flex items-center font-light pt-3 md:pt-6 text-gray-700 dark:text-gray-400">
                <AiOutlineMail size={20} className="text-[var(--primary)]" />
                <p className="text-base px-2">
                  info@lumpsumtech.com
                </p>
              </div>
              <div className="flex items-center font-light pt-3 md:pt-6 text-gray-700 dark:text-gray-400">
                <IoLocationSharp size={20} className="text-[var(--primary)]" />
                <p className="text-base px-2">
                  plot 52, kampala Road
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[var(--tertiary)] h-auto p-6 md:p-12 rounded-md shadow-sm">
            <form
              action="https://getform.io/f/aejyywdb"
              method="POST"
              className="flex flex-col space-y-4"
            >
              <div>
                <label htmlFor="" className="text-base font-light">
                  Enter Name
                </label>

                <input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  className="ring-1 ring-white w-full font-light rounded-sm px-4 py-3 mt-2 focus:ring-2 focus:ring-teal-300 outline-none"
                />
              </div>
              <div className="pt-6">
                <label htmlFor="" className="text-base font-light">
                  Email Address
                </label>

                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  className="ring-1 ring-white w-full font-light rounded-sm px-4 py-3 mt-2 focus:ring-2 focus:ring-teal-300 outline-none"
                />
              </div>
              <div className="pb-4 pt-6">
                <label htmlFor="" className="text-base font-light">
                  Message
                </label>

                <textarea
                  rows={4}
                  placeholder="Message..."
                  className="ring-1 ring-white w-full font-light rounded-sm px-4 py-3 mt-2 focus:ring-2 focus:ring-teal-300 outline-none"
                />
              </div>
              <div className="text-center w-full md:pb-3">
                <Button>Send Message</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <iframe
          width="100%"
          height="600"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=plot%2052,%20kampala,road+(lumpsum%20technologies)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps systems</a>
        </iframe>
      </div>
    </div>
  );
};

export default ContactUs;
