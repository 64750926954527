import React from "react";
import { MdCheck } from "react-icons/md";

const Features = () => {
  return (
    <div className="h-auto w-full bg-white">
      <div className="max-w-[1280px] mx-auto flex flex-col items-center justify-center mt-0 mb-10 px-4">
        <h1 className="text-3xl font-bold text-black text-center pb-10">
          Product Features
        </h1>

        <div className="grid grid-cols-1 pt-10 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-7 lg:gap-9">
          <div className="max-w-sm h-[560px] p-10 bg-[var(--primary)] border-2 border-[var(--primary)] text-white rounded-2xl shadow-md sm:p-6 md:p-8">
            <div>
              <h2 className="uppercase font-semibold text-lg md:text-xl">
                Relync
              </h2>
              <p className="pt-3 font-light text-base">
                This system was designed to address real estate troubles faced
                by many property owners and managers.
              </p>
            </div>

            <div className="pt-8 md:pt-16 lg:pt-7 grid gap-5 font-light text-sm">
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Tenant and lease management.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Property maintenance and repair tracking.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Financial and accounting tools.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Asset Management.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Lease Management.</li>
              </ul>
            </div>
          </div>
          <div className="max-w-sm h-[560px] p-10 bg-[var(--primary)] border-2 border-[var(--primary)] text-white rounded-2xl shadow-md sm:p-6 md:p-8">
            <div>
              <h2 className="uppercase font-semibold text-lg md:text-xl">
                Bankroll
              </h2>
              <p className="pt-3 font-light text-base">
                Welcome to Bankroll, the premier Loans Management System for
                lending companies.
              </p>
            </div>

            <div className="pt-8 md:pt-16 lg:pt-7 grid gap-5 font-light text-sm">
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Loan Portfolio Management.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Savings Managementc.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Share Management.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Modifiable Chart of Accounts.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Mobile Money and SMS.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Client Biodata Management.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Security and Audit.</li>
              </ul>
            </div>
          </div>
          <div className="max-w-sm h-[560px] p-10 bg-[var(--primary)] border-2 border-[var(--primary)] text-white rounded-2xl shadow-md sm:p-6 md:p-8">
            <div>
              <h2 className="uppercase font-semibold text-lg md:text-xl">
                Cashnet
              </h2>
              <p className="pt-3 font-light text-base">
                Ultimate accounting solution for small businesses.
              </p>
            </div>

            <div className="pt-8 md:pt-16 lg:pt-7 grid gap-5 font-light text-sm">
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Invoicing</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Financial Reporting.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Expense Tracking.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Income Tracking.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Multi-currency support.</li>
              </ul>
              <ul className="flex items-center gap-2">
                <MdCheck />
                <li>Invoice Templates.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
