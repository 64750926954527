import React from "react";
import { AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { MdFacebook } from "react-icons/md";
import { NavLink } from "react-router-dom";

const members = [
  {
    id: 1,
    name: "BAZIWE SHAFIK",
    role: "CHIEF EXECUTIVE OFFICER",
    description:
      "Baziwe Shafik is the dynamic and visionary CEO of Lumpsum Technologies. With over 15 years of experience in the tech industry, Baziwe has a proven track record of driving innovation and leading successful teams. Under his leadership, Lumpsum Technologies has achieved significant growth and established itself as a leader in cutting-edge software solutions. Known for his strategic thinking and passion for technology, Baziwe is dedicated to transforming the digital landscape and empowering businesses with advanced technological tools. His commitment to excellence and his ability to inspire those around heim make him a respected and influential figure in the industry.",
    image:
      "https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_640.png",
  },
  {
    id: 2,
    name: "OPIO SIMON PETER",
    role: "CHIEF FINANCIAL OFFICER",
    description:
      "Simon Peter Opio is a seasoned Chief Financial Officer (CFO) at Lumpsum Technologies, bringing a wealth of financial expertise and strategic acumen to the organization. With a strong background in financial management and analysis, Simon Peter plays a pivotal role in guiding Lumpsum Technologies' fiscal direction and ensuring the company's financial health.As CFO, Simon Peter oversees all aspects of financial planning, budgeting, and forecasting, driving efficiency and maximizing profitability. His keen analytical skills enable him to identify opportunities for growth and optimization, while also mitigating risks effectively.",
    image:
      "https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_640.png",
  },
  {
    id: 3,
    name: "OKALEBO JAMES",
    role: "DEVELOPMENT AND SUPPORT",
    description:
    "OKALEBO JAMES is the Lead Developer at Lumpsum Technologies, where he spearheads innovative software development projects and drives technological advancements. With a deep expertise in various programming languages and a passion for solving complex problems, James plays a crucial role in the company's growth and success. His leadership and technical acumen ensure the delivery of high-quality, scalable solutions that meet the dynamic needs of clients. James is committed to fostering a collaborative environment and staying at the forefront of emerging technologies to keep Lumpsum Technologies ahead in the industry.",
    image:
    "https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_640.png",
    },
      {
        id: 4,
        name: "AINEBYOONA CHRISTINE",
        role: "HEAD SALES AND MARKETING",
        description:
          "Ainebyoone Christine is the Business Development Manager at Lumpsum Technologies, where she plays a pivotal role in driving the company's growth and expanding its market presence. With a keen eye for identifying new business opportunities and a strategic approach to partnerships and client relations, Christine excels at fostering long-term relationships that benefit both the company and its clients. Her expertise in market analysis, coupled with her innovative mindset, helps Lumpsum Technologies stay ahead in the competitive tech landscape. Christine's dedication to excellence and her dynamic leadership style make her an invaluable asset to the team.",
        image:
          "https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_640.png",
      },
  {
    id: 5,
    name: "OJOK GODFREY",
    role: "BACKEND DEVELOPER",
    description:
      "OJOK GODFREY is a proficient backend developer at Lumpsum Technologies. With a strong foundation in server-side programming, database management, and API development, Godfrey excels in building robust, scalable backend systems. His expertise includes working with technologies like Node.js, Express, and various database systems such as MySQL and MongoDB. At Lumpsum Technologies, he plays a pivotal role in ensuring seamless data integration, security, and high-performance applications. Godfrey's commitment to continuous learning and innovation drives his ability to solve complex challenges and contribute significantly to the success of his projects and team.",
    image:
      "https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_640.png",
  },
  {
    id: 6,
    name: "MAYUNGWE JUMA",
    role: "FRONTEND DEVELOPER",
    description:
      "Mayungwe Juma is a skilled front-end developer at Lumpsum Technologies, where he specializes in creating dynamic, user-friendly web applications. With a keen eye for design and a deep understanding of modern web technologies, Juma excels in translating complex requirements into seamless and intuitive user interfaces. His expertise in HTML, CSS, JavaScript, and popular frameworks like React and Next Js ensures that Lumpsum Technologies' digital products are both visually appealing and highly functional. Passionate about continuous learning and innovation, Juma is dedicated to staying ahead of industry trends and delivering top-quality solutions that enhance user experiences.",
    image:
      "https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_640.png",
  },
];

const Team = () => {
  return (
    <div>
      <div>
        <h1 className="capitalize text-3xl font-bold leading-none tracking-tight">
          meet our team
        </h1>
        <div className="pt-7 md:pt-14 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {members.map((member) => (
            <div key={member.id} className="border rounded-lg p-4 shadow-lg">
              <img src={member.image} alt={member.name} className="h-[400px] w-full object-cover" />

              <div className="p-4">
                <h2 className="text-base font-semibold mb-2">{member.name}</h2>
                <h2 className="text-base font-medium mb-2">{member.role}</h2>
                <p className="font-light text-sm leading-relaxed mb-4 text-gray-700">
                  {member.description}
                </p>
                <div className="flex items-center gap-5 text-[var(--primary)]">
                  <NavLink>
                    <AiFillLinkedin size={20} />
                  </NavLink>
                  <NavLink>
                    <FaXTwitter size={20} />
                  </NavLink>
                  <NavLink>
                    <AiOutlineInstagram size={20} />
                  </NavLink>
                  <NavLink>
                    <MdFacebook size={20} />
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
