import React from "react";
import aboutImage from "../assets/annie-spratt-FSFfEQkd1sc-unsplash.jpg";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import Team from "../components/Team";

const AboutUs = () => {
  return (
    <div className="h-auto w-full">
      <div className="max-w-[1280px] mx-auto my-36 px-4">
        {/* intro section */}
        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.8 }}
          className="flex flex-col md:flex-row items-center gap-10"
        >
          <div className="shadow-sm rounded-3xl mx-auto">
            <img className="rounded-3xl" src={aboutImage} alt="" />
          </div>

          
          <div>
            <h1 className="capitalize mb-4 text-3xl font-bold leading-none tracking-tight">
              Who We Are?
            </h1>
            <p className="font-light text-base leading-7 text-gray-700">
              Lumpsum Technologies is a software company incorporated in 2017
              that provides real estate and financial management solutions to
              businesses and individuals. Our platforms includes property
              management modules for tracking leases, tenants, and maintenance
              and a financial management modules for tracking income, expenses,
              and generating reports. Our systems are user-friendly, efficient
              and offer customer support and training services to Our clients.
              We cater to a wide range of customers ranging from small to large
              consortiums
            </p>
          </div>
        </motion.div>

        {/* company values */}
        <div className="mt-16">
          <div className="">
            <h1 className="capitalize mb-4 text-center text-3xl font-bold leading-none tracking-tight">
              Our Values
            </h1>
          </div>
          <div className="flex flex-col gap-6 md:flex-row justify-center pt-6">
            <div className="block max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900">
                Mission
              </h5>
              <p class="font-light text-sm leading-relaxed text-gray-700">
                At Lumpsum Technologies, our mission is to revolutionize the
                software industry by delivering innovative, reliable, and
                user-centric solutions. We are dedicated to empowering
                businesses with cutting-edge technology that enhances
                efficiency, drives growth, and fosters success in a rapidly
                evolving digital landscape.
              </p>
            </div>
            <div className="block max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900">
                Vision
              </h5>
              <p class="font-light text-sm leading-relaxed text-gray-700">
                Our vision is to be a global leader in software development,
                recognized for our exceptional products, commitment to customer
                satisfaction, and pioneering approach to technology. We aspire
                to create a future where our software solutions are integral to
                the success of businesses worldwide, enabling them to achieve
                their full potential.
              </p>
            </div>
            <div className="block max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900">
                Future Prospects
              </h5>
              <p class="font-light text-sm leading-relaxed text-gray-700">
                Looking ahead, Lumpsum Technologies envisions a future where we
                expand our global footprint, tapping into emerging markets and
                forging strategic partnerships with industry leaders. We aim to
                diversify our product portfolio, integrating advanced
                technologies to offer even more sophisticated and efficient
                solutions.
              </p>
            </div>
          </div>
        </div>

        {/* team section */}
        <div className="text-center pt-12 md:pt-24">
          <Team />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
