import React from "react";
import Hero from "../components/Hero";
import WhyUs from "../components/WhyUs";
import Benefits from "../components/Benefits";
import Faq from "../components/Faq";
import Features from "../components/Features";
import ClientSlider from "../components/ClientSlider";

const Home = () => {
  return (
    <div>
      <Hero />
      <ClientSlider/>
      <WhyUs />
      <Benefits />
      <Features/>
      <Faq />
    </div>
  );
};

export default Home;
