import React from "react";
import Accordion from "./Accordion.jsx";
import { motion } from "framer-motion";
import { fadeIn } from "../variants.js";

const Faq = () => {
  return (
    <div className="max-w-[1280px] mx-auto h-auto md:h-[800px] flex flex-col justify-center pb-8 pt-8 px-4 md:px-0 bg-white">
      <motion.h1
        variants={fadeIn("down", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.8 }}
        className="mb-4 text-3xl font-bold capitalize text-center leading-none tracking-tight"
      >
        frequently asked questions
      </motion.h1>

      <div className="container pt-10 p-2 bg-white md:grid md:grid-cols-1">
        <Accordion
          title="What does your software company do?"
          answer="We specialize in developing cutting-edge software solutions tailored to meet the specific needs of businesses across various industries."
        />
        <Accordion
          title="Can you handle both small and large scale projects?"
          answer="Certainly. We have a portfolio of successful projects across various industries. We can provide references and case studies upon request to demonstrate our expertise and track records of delivering high-quality solutions."
        />
        <Accordion
          title="Do you offer custom software development services?"
          answer="Yes, we excel in custom software development. Our team works closely to understand their unique requirements and develop tailored solutions that address their specific business challenges."
        />
        <Accordion
          title="Can you help with software maintain and support?"
          answer="Absolutely. We provide ongoing maintance and support services to ensure that your software remains up-to-date, secure and performing optimally. Our dedicated support team is available to address any issues and provide timely assistance."
        />
        <Accordion
          title="What is your pricing model for software development?"
          answer="Our pricing model depends on factors such as project complexity, scope, timeline and specific client requirements. We offer flexible pricing options, including fixed-price, time and materials and dedicated team models, tailored to suit your budget and preferences."
        />
        <Accordion
          title="How do we get started with a project at your company?"
          answer="Simply reach out to us through our website or contact information provided and one of our representatives will be in touch to discuss your project requirements, goals and timelines. We'll then work together to create a customised plan and kick-start the development process."
        />
      </div>
    </div>
  );
};

export default Faq;
