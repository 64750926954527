import React from 'react'

const Bankroll = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center bg-gray-100 pt-10">
    <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg overflow-hidden md:flex">
      <div className="md:w-1/2">
        <img 
          src="https://via.placeholder.com/600x400" 
          alt="Product" 
          className="w-full h-full object-cover"
        />
      </div>
      <div className="md:w-1/2 p-6">
        <h1 className="text-3xl font-semibold text-gray-800 mb-2">Product Name</h1>
        <p className="text-gray-600 mb-4">Product category or tagline.</p>
        <div className="text-2xl font-bold text-gray-900 mb-4">$99.99</div>
        <p className="text-gray-700 mb-6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <button className="w-full bg-blue-500 text-white p-3 rounded-md shadow-md hover:bg-blue-600 transition duration-300 mb-4">
          Add to Cart
        </button>
        <button className="w-full bg-green-500 text-white p-3 rounded-md shadow-md hover:bg-green-600 transition duration-300">
          Buy Now
        </button>
      </div>
    </div>
    <div className="max-w-6xl mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-6 p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-3">Product Details</h2>
      <p className="text-gray-700 mb-6">
        More detailed information about the product. This section can contain multiple paragraphs, bullet points, or any other detailed descriptions.
      </p>
      <h3 className="text-xl font-semibold text-gray-800 mb-3">Features</h3>
      <ul className="list-disc list-inside text-gray-700 mb-6">
        <li>Feature 1</li>
        <li>Feature 2</li>
        <li>Feature 3</li>
        <li>Feature 4</li>
      </ul>
      <h3 className="text-xl font-semibold text-gray-800 mb-3">Specifications</h3>
      <ul className="list-disc list-inside text-gray-700">
        <li>Specification 1</li>
        <li>Specification 2</li>
        <li>Specification 3</li>
        <li>Specification 4</li>
      </ul>
    </div>
  </div>
  )
}

export default Bankroll