import React from "react";

const Button = (props) => {
  return (
    <button className="bg-[var(--secondary)] text-white text-base py-1.5 px-4 rounded-lg  hover:opacity-90 transition-all duration-500">
      {props.children}
    </button>
  );
};

export default Button;
