import React from "react";
import { FaCube } from "react-icons/fa";
import serviceHeaderImage from "../assets/services-header.jpg";
import Button from "../components/Button";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { NavLink } from "react-router-dom";

const Services = () => {
  return (
    <div className="h-auto w-full">
      <div className="container">
        <div className="my-20 px-4 md:my-40 flex flex-col items-center">
          <motion.h1
            variants={fadeIn("down", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="font-bold text-2xl text-center md:text-5xl text-[var(--primary)]"
          >
            Innovative Software Solutions
          </motion.h1>
          <motion.p
            variants={fadeIn("down", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="font-light text-lg text-gray-700 dark:text-gray-400 text-center md:text-2xl py-4 md:py-8"
          >
            Discover our wide range of cutting-edge software products and
            services.
          </motion.p>

          <div className="grid md:grid-cols-2 items-center pt-6 md:pt-12">
            <div className="pb-4">
              <motion.h1
                variants={fadeIn("right", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                className="font-bold text-2xl md:text-4xl"
              >
                Empowering Businesses with
                <br /> Cutting-Edge Software
                <br /> Solutions
              </motion.h1>
            </div>
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              <h2 className="font-normal text-xl text-center md:text-2xl">
                At Lumpsum Technologies, we offer innovative software products
                that drive growth and efficiency for businesses.
              </h2>
              <div className="grid md:grid-cols-2 gap-3 py-4 md:py-8">
                <div className="grid items-center text-center h-auto border-1 p-3 border-[var(--primary)] rounded-3xl shadow-md">
                  <div>
                    <FaCube
                      size={25}
                      className="text-[var(--primary)] mx-auto"
                    />
                    <h3 className="font-normal pt-5 text-xl md:text-xl">
                      Boost Performance
                    </h3>
                    <p className="font-light pt-5 pb-5 text-lg md:text-xl text-gray-700 dark:text-gray-400">
                      Our software solutions optimize
                      <br /> operations, streamline workflows, and
                      <br /> enhance productivity across industries.
                    </p>
                  </div>
                </div>
                <div className="grid items-center text-center h-auto border-1 p-3 border-[var(--primary)] rounded-3xl shadow-md">
                  <div>
                    <FaCube
                      size={25}
                      className="text-[var(--primary)] mx-auto"
                    />
                    <h3 className="font-normal pt-5 text-xl md:text-xl">
                      Increase ROI
                    </h3>
                    <p className="font-light pt-5 pb-5 text-lg md:text-xl text-gray-700 dark:text-gray-400">
                      Experience higher returns on investment
                      <br /> with our scalable and customizable
                      <br /> software solutions.
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="px-4 grid items-center mt-8 md:mt-16"
        >
          <h1 className="font-bold text-2xl md:text-4xl text-[var(--primary)] text-center">
            Our Comprehensive Range of Services
          </h1>
          <p className="font-light text-xl text-gray-700 dark:text-gray-400 md:text-2xl py-4 md:py-8 text-center">
            At Lumpsum Technologies, we offer a wide range of software services
            to meet your business needs. From custom software development to IT
            consulting, our team of experts is here to help.
          </p>

          <div className="grid md:grid-cols-3 gap-4 pt-5 md:pt-10">
            <div className="grid items-center text-center h-auto border-1 p-3 border-[var(--primary)] rounded-3xl shadow-md">
              <div>
                <FaCube size={25} className="text-[var(--primary)] mx-auto" />
                <h3 className="font-medium pt-5 py-2 md:py-4 text-xl">
                  Custom Software Development
                </h3>
                <p className="font-light pt-5 pb-5 text-lg md:text-xl text-gray-700 dark:text-gray-400">
                  We specialize in developing tailored software solutions
                  <br /> that align with your unique business requirements and
                  <br /> goals.
                </p>
              </div>
            </div>
            <div className="grid items-center text-center h-auto border-1 p-3 border-[var(--primary)] rounded-3xl shadow-md">
              <div>
                <FaCube size={25} className="text-[var(--primary)] mx-auto" />
                <h3 className="font-medium pt-5 py-2 md:py-4 text-xl">
                  IT Consulting Services
                </h3>
                <p className="font-light  text-gray-700 dark:text-gray-400 pt-5 pb-5 text-lg md:text-xl">
                  Our experienced consultants provide strategic
                  <br /> guidance and support to optimize your IT
                  <br /> infrastructure and processes.
                </p>
              </div>
            </div>
            <div className="grid items-center text-center h-auto border-1 p-3 border-[var(--primary)] rounded-3xl shadow-md">
              <div>
                <FaCube size={25} className="text-[var(--primary)] mx-auto" />
                <h3 className="font-medium pt-5 py-2 md:py-4 text-xl">
                  Software Maintaince And Support
                </h3>
                <p className="font-light pt-5 pb-5 text-lg md:text-xl text-gray-700 dark:text-gray-400">
                  Absolutely. We provide ongoing maintance and
                  <br /> support services to ensure that your software remains
                  <br />
                  up-to-date, secure and performing optimally.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="grid md:grid-cols-2 gap-8 pt-10 pb-6 md:pb-12 px-4 md:pt-20">
          <motion.div
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="rounded-3xl"
          >
            <img
              className="w-full h-[50vh] shadow-md object-cover rounded-3xl"
              src={serviceHeaderImage}
              alt=""
            />
          </motion.div>

          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }}
            className="flex flex-col items-center justify-center"
          >
            <h1 className="font-bold text-2xl md:text-5xl text-center text-[var(--primary)]">
              Discover Our Powerful Software Solutions
            </h1>
            <p className="font-light pt-3 md:pt-6 text-xl md:text-2xl text-center text-gray-700 dark:text-gray-400">
              Transform your business with our innovative software products and
              services.
            </p>
            <div className="pt-3 md:pt-6">
              <Button>
                <NavLink to="/contact-us">Contact</NavLink>
              </Button>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Services;
