import React from "react";
import whyus from "../assets/medium-shot-smiley-people-posing.png";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const WhyUs = () => {
  return (
    <div className="h-auto md:mt-20 w-full">
      <div className="max-w-[1280px] mx-auto flex flex-col-reverse items-center justify-between md:flex md:items-center h-auto p-4 mt-10 md:mt-0 md:flex-row md:h-full">
        <motion.div
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="flex flex-col justify-center pt-8"
        >
          <div className="border-l-2 border-l-[var(--secondary)]">
            <h1 className="capitalize font-semibold text-xl pl-5">
              powerful software solutions
            </h1>
            <p className="pl-5 font-light text-gray-700 dark:text-gray-400 text-base pt-2">
              Our software products are designed to streamline your business
              operations
              <br /> and increase efficiency.
            </p>
          </div>

          <div className="pl-5 pt-8 md:pt-10">
            <h1 className="capitalize font-semibold text-xl">
              intuitive user interface
            </h1>
            <p className="pt-2 font-light text-gray-700 dark:text-gray-400 text-base">
              We prioritize user experience, ensuring that our software is easy
              to navigate
              <br /> and use.
            </p>
          </div>

          <div className="pl-5 pt-8 md:pt-10">
            <h1 className="capitalize font-semibold text-xl">
              advanced analytics capabilities{" "}
            </h1>
            <p className="pt-2 font-light text-gray-700 dark:text-gray-400 text-base">
              Gain valuable insights into your business with our powerful
              analytics tools.
            </p>
          </div>
        </motion.div>

        <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          className="md:w-2/4 rounded-3xl shadow-md"
        >
          <img className="rounded-3xl" src={whyus} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default WhyUs;
